import { Row } from "antd"
import { FC, useContext } from "react"
import { ContentfulContext } from "../../context"
import { useBreakpoints } from "../hooks/useBreakpoints"
import { MobileAppBtn, SvgIcon, Typography } from "../ui"
import "./index.less"
import { useApp } from "../app"

const Footer: FC = () => {
  const { isMobile, isTablet } = useBreakpoints()
  const { data } = useContext(ContentfulContext)
  const { lang } = useApp()

  const { facebookLink, twitterLink, instagramLink, linkedinLink } = data?.[0].fields.footer?.[`en-US`]?.fields ?? {}
  const { diabetes,privacy,terms,contactUs,noticeOfPrivacyPractices } = data?.[0].fields.footerMenu?.[`en-US`].fields ?? {}
  const appleStoreUrl = `https://apps.apple.com/us/app/wondr-health/id1521728956`
  const googlePlayUrl = `https://play.google.com/store/apps/details?id=com.ns412.naturallyslim&hl=en&gl=US`

  const footerLinks = [
    {
      title: contactUs?.[`${lang}-US`],
      link: "https://support.wondrhealth.com/hc/en-us",
    },
    {
      title: privacy?.[`${lang}-US`],
      link: "https://wondrhealth.com/privacy-policy/",
    },
    {
      title: noticeOfPrivacyPractices?.[`${lang}-US`],
      link: "https://wondrhealth.com/privacy-practices-notice/",
    },
    {
      title: diabetes?.[`${lang}-US`],
      link: "https://wondrhealth.com/blog/wondr-diabetes/",
    },
    {
      title: terms?.[`${lang}-US`],
      link: "https://wondrhealth.com/terms-of-use/",
    },
  ]

  const footerTablet = [
    {
      title: privacy?.[`${lang}-US`],
      link: "https://wondrhealth.com/privacy-policy/",
    },
    {
      title: terms?.[`${lang}-US`],
      link: "https://wondrhealth.com/terms-of-use/",
    },
    {
      title: contactUs?.[`${lang}-US`],
      link: "https://support.wondrhealth.com/hc/en-us",
    },
    {
      title: "©2022 Wondr Health",
      link: "",
    },
    {
      title: noticeOfPrivacyPractices?.[`${lang}-US`],
      link: "https://wondrhealth.com/privacy-practices-notice/",
    },
    {
      title: diabetes?.[`${lang}-US`],
      link: "https://wondrhealth.com/blog/wondr-diabetes/",
    },
  ]

  return (
    <>
      <Row
        justify={"center"}
        style={{
          alignItems: "center",
          flexDirection: "column",
          paddingBottom: isMobile ? 65 : 150,
        }}
      >
        <Row justify={"center"} style={{ paddingBottom: 40, gap: 16 }}>
          {facebookLink && (
            <a className={"soc-icon"} href={facebookLink}>
              <SvgIcon type={"fbDark"} />
            </a>
          )}
          {twitterLink && (
            <a className={"soc-icon"} href={"https://twitter.com/WondrHealth"}>
              <SvgIcon type={"twitterDark"} />
            </a>
          )}
          {instagramLink && (
            <a className={"soc-icon"} href={"https://www.instagram.com/wondrhealth/"}>
              <SvgIcon type={"instDark"} />
            </a>
          )}
          {linkedinLink && (
            <a className={"soc-icon"} href={"https://www.linkedin.com/company/wondrhealth/"}>
              <SvgIcon type={"linkDark"} />
            </a>
          )}
        </Row>
        <Row justify={isMobile ? "start" : "center"} style={{ paddingBottom: 40, gap: 20 }}>
          <MobileAppBtn link={googlePlayUrl} type={"google-play"} />
          <MobileAppBtn link={appleStoreUrl} type={"app-store"} />
        </Row>
      </Row>
      {isTablet ? (
        <div className={"footer-links"}>
          {footerTablet.map((item, i) => (
            <Typography.Text className={"footer-link"} key={i} size={"s14"}>
              <a style={{ color: "#2D103D" }} href={item.link}>
                {item.title}
              </a>
            </Typography.Text>
          ))}
        </div>
      ) : (
        <div className={"footer-links"}>
          <Typography.Text className={"footer-link"} style={{ color: "#2D103D" }} size={"s14"}>
            © {new Date().getFullYear()} Wondr Health
          </Typography.Text>
          {footerLinks.map((item, i) => (
            <Typography.Text className={"footer-link"} key={i} size={"s14"}>
              <a style={{ color: "#2D103D" }} href={item.link}>
                {item.title}
              </a>
            </Typography.Text>
          ))}
        </div>
      )}
    </>
  )
}

export { Footer }
