import { Select } from "antd"
import { SvgIcon } from ".."
import { useApp } from "../../app"
import "./index.less"
import { useEffect } from "react"
import { useBreakpoints } from "../../hooks/useBreakpoints"

const { Option } = Select

const LanguageSelector = () => {
  const { setLang,lang } = useApp()
  const { isMobile } = useBreakpoints()
  const handleChange = (value: string) => {
    if (setLang) {
      setLang(value)
    }
  }


  const options = lang === "en" ? [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
  ] : [ { value: "es", label: "Español" },
    { value: "en", label: "Inglés" },
]

  useEffect(() => {
    const selectedValue = document.querySelector(".ant-select-selection-item")
    if (selectedValue) {
      selectedValue.innerHTML = lang === "en" ? "Eng" : "Esp"
    }
  }, [lang])

  return (
    <div className={"lang-row"}>
      <SvgIcon type={"language"} />
      <Select
        dropdownAlign={{ offset:[20,isMobile ? 6 : 16 ] }}
        getPopupContainer={trigger => trigger.parentNode}
        placement={"bottomRight"}
        menuItemSelectedIcon={<SvgIcon type={"checkmark"} />}
        popupClassName={"language-selector-popup"}
        onChange={handleChange}
        popupMatchSelectWidth={false}
        className={"language-selector"}
        value={lang}
        bordered={false}
      >
        {options.map(option => (
          <Option className={"option"} key={option.value} value={option.value} label={option.label}>
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export { LanguageSelector }
