import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { ConfigProvider } from "antd"
import { createContext, FC, memo, PropsWithChildren, useContext, useState } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { version } from "../../../package.json"
import { ContentfulContextProvider } from "../../context"
import introspection from "../../graphql"
import routes from "../../pages"
import theme from "../../themes"

type ContextProps = {
  app: { version: string }
  lang: string
  setLang?: (lang: string) => void
}

const app: ContextProps["app"] = { version }

const Context = createContext<ContextProps | undefined>(undefined)

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  const [lang, setLang] = useState(props.lang || "en")
  const contextValue = {
    ...props,
    lang,
    setLang: setLang,
  }

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

const useApp = (): ContextProps => {
  const context = useContext(Context)

  if (!context) {
    throw new Error("useApp must be used within a ContextProvider")
  }

  return context
}

const client = new ApolloClient({
  link: createHttpLink({
    uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
    credentials: "same-origin",
  }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const router = createBrowserRouter(routes)

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <ContentfulContextProvider>
      <ContextProvider app={app} lang='en'>
        <ConfigProvider theme={theme}>
          <RouterProvider router={router} />
        </ConfigProvider>
      </ContextProvider>
    </ContentfulContextProvider>
  </ApolloProvider>
))

export { useApp }

export default App
