import "./index.less"

import React, { FC } from "react"

import { SvgIcon } from "../icon"
import appleText from "./images/app-store-text.png"
import appleTitle from "./images/app-store-title.png"
import googleText from "./images/google-text.png"
import googleTitle from "./images/google-title.png"

type Props = {
  type: "app-store" | "google-play"
  link: string
}
const MobileAppBtn: FC<Props> = ({ link, type }) => {
  const text = () => {
    if (type === "app-store") return <img src={appleText} alt='' />
    if (type === "google-play") return <img src={googleText} alt={"google-play"} />
    return null
  }

  const title = () => {
    if (type === "app-store") return <img src={appleTitle} alt='' />
    if (type === "google-play") return <img src={googleTitle} alt={"google-play"} />
    return null
  }

  return (
    <a href={link} className={"app-btn"}>
      <div className='btn-content'>
        <SvgIcon type={type === "app-store" ? "apple" : "google"} />
        <div className='text-block'>
          <div className='text-image'>{text()}</div>
          <div className='title-image'>{title()}</div>
        </div>
      </div>
    </a>
  )
}

export { MobileAppBtn }
