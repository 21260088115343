import { Col, Row, Space } from "antd"
import { FC, useEffect, useState } from "react"

import { useBreakpoints } from "../hooks/useBreakpoints"
import { LanguageSelector, SvgIcon } from "../ui"

const Header: FC = () => {
  const { isDesktop } = useBreakpoints()

  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.ant-layout-header')
      const scrolled = window.pageYOffset > 20
      setIsScrolled(scrolled)
      if (header) {
        if (scrolled) {
          header.classList.add('is-open')
        } else {
          header.classList.remove('is-open')
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])


  const scrolleddContent = <Row align={"middle"} justify={"space-between"}>
    {!isDesktop && (
      <SvgIcon className={"mobile-logo-header"} type={"mobileLogo"} fill={"#2E0F3D"} />
    )}
    <Space direction={"horizontal"}>
      {isDesktop && (
        <><SvgIcon type={"door"} /><SvgIcon type={"textLogo"} /></>
      )}
    </Space>
    <Col>
      <LanguageSelector />
    </Col>
  </Row>

  const fixedContent = <Row align={"middle"} justify={"space-between"}>
    {!isDesktop && (
      <SvgIcon className={"mobile-logo-header"} type={"mobileLogo"} fill={"#fff"} />
    )}
    {isDesktop && (
      <SvgIcon type={"logo"} className={"main-logo-fixed"} />
    )}
    <Col>
      <LanguageSelector />
    </Col>
  </Row>

  return (
    <div className={`header-container ${!isScrolled ? "fixed" : ""}`}>
      {!isScrolled ? fixedContent : scrolleddContent}
    </div>
  )
}

export { Header }
