import { FC, lazy } from "react"
import { RouteObject } from "react-router-dom"
import AuthGuard from "../components/layout/AuthGuard"

import { EntryLayout } from "src/components/layout/EntryLayout"
import DefaultLayout from "../components/layout"

const Home = lazy<FC>(() => import("./home"))
const Entry = lazy<FC>(() => import("./entry"))

const routes: RouteObject[] = [
  {
    element: (
      <AuthGuard>
        <DefaultLayout />
      </AuthGuard>
    ),
    children: [
      {
        element: <Home />,
        path: "/",
      },
      {
        element: <Home />,
        path: "/welcome",
      },
    ],
  },
  {
    element: <EntryLayout />,
    children: [{ element: <Entry />, path: "" }],
  },
  {
    element: (
      <AuthGuard>
        <EntryLayout />
      </AuthGuard>
    ),
    path: "entry",
    children: [{ element: <Entry />, path: "" }],
  },
]

export default routes
