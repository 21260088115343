import "./index.less"

import { Layout } from "antd"
import { FC } from "react"
import { Outlet } from "react-router-dom"

const EntryLayout: FC = () => (
  <Layout>
    <Outlet />
  </Layout>
)

export { EntryLayout }
