import "./index.less"

import { Typography as Wrapped } from "antd"
import { ParagraphProps } from "antd/es/typography/Paragraph"
import { TextProps } from "antd/es/typography/Text"
import { TitleProps } from "antd/es/typography/Title"
import classnames from "classnames"
import { FC } from "react"

type Size = "s14" | "s16" | "s18" | "s20" | "s24" | "s26" | "s28" | "s32"

type LineHeight = "lh-md" | "lh-lg"

type EnhancedTypographyProps = {
  weight: "w400" | "w500" | "w600" | "w700"
  color: "primary" | "white"
  centered: boolean
  lh: LineHeight
}

type EnhancedTitleProps = TitleProps & EnhancedTypographyProps

type EnhancedParagraphProps = ParagraphProps & EnhancedTypographyProps & { size: Size }

type EnhancedTextProps = TextProps & EnhancedTypographyProps & { size: Size }

const Title: FC<Partial<EnhancedTitleProps>> = ({
  className,
  centered = false,
  lh = "lh-md",
  weight,
  color = "main",
  ...baseProps
}) => <Wrapped.Title className={classnames(className, weight, color, lh, { centered: centered })} {...baseProps} />

const Paragraph: FC<Partial<EnhancedParagraphProps>> = ({
  className,
  lh = "lh-md",
  centered = false,
  size,
  weight,
  color = "main",
  ...baseProps
}) => (
  <Wrapped.Paragraph
    className={classnames(className, weight, color, size, lh, { centered: centered })}
    {...baseProps}
  />
)

const Text: FC<Partial<EnhancedTextProps>> = ({
  className,
  centered = false,
  lh = "lh-md",
  size,
  weight,
  color = "main",
  ...baseProps
}) => <Wrapped.Text className={classnames(className, weight, color, size, lh, { centered: centered })} {...baseProps} />

const Typography = { Title, Paragraph, Text }

export { Typography }
