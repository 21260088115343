import { FC, SVGProps } from "react"
import { ReactComponent as Apple } from "./images/Apple.svg"
import { ReactComponent as Brain } from "./images/Brain.svg"
import { ReactComponent as Clock } from "./images/Clock.svg"
import { ReactComponent as Complete } from "./images/Complete.svg"
import { ReactComponent as Cross } from "./images/Cross.svg"
import { ReactComponent as Door } from "./images/Door.svg"
import { ReactComponent as Facebook } from "./images/Facebook.svg"
import { ReactComponent as Google } from "./images/Google.svg"
import { ReactComponent as Instagramm } from "./images/Insta.svg"
import { ReactComponent as Linkedin } from "./images/Linkedin.svg"
import { ReactComponent as Logo } from "./images/Logo.svg"
import { ReactComponent as Mute } from "./images/Mute.svg"
import { ReactComponent as Next } from "./images/Next.svg"
import { ReactComponent as Phone } from "./images/Phone.svg"
import { ReactComponent as Play } from "./images/Play.svg"
import { ReactComponent as PlayPrimary } from "./images/PlayPrimary.svg"
import { ReactComponent as Prev } from "./images/Prev.svg"
import { ReactComponent as TextLogo } from "./images/TextLogo.svg"
import { ReactComponent as Twitter } from "./images/Twitter.svg"
import { ReactComponent as Unmute } from "./images/Unmute.svg"
import { ReactComponent as CheckMarkIcon } from "./images/checkmark.svg"
import { ReactComponent as FbDark } from "./images/facebook-dark.svg"
import { ReactComponent as InstDark } from "./images/instagram-dark.svg"
import { ReactComponent as LanguageIcon } from "./images/language.svg"
import { ReactComponent as LinkDark } from "./images/linkedin-dark.svg"
import { ReactComponent as LogoEntry } from "./images/logo_entry.svg"
import { ReactComponent as NextTablet } from "./images/next-tablet.svg"
import { ReactComponent as PrevTablet } from "./images/prev-tablet.svg"
import { ReactComponent as TwitterDark } from "./images/twitter-dark.svg"
import { ReactComponent as LogoMobile } from "./images/mobile-logo.svg"

export type IconTypes =
  | "mobileLogo"
  | "checkmark"
  | "language"
  | "phone"
  | "brain"
  | "logo"
  | "play"
  | "google"
  | "apple"
  | "mute"
  | "unmute"
  | "complete"
  | "textLogo"
  | "twitter"
  | "insta"
  | "facebook"
  | "linkedin"
  | "clock"
  | "prev"
  | "prevTablet"
  | "next"
  | "nextTablet"
  | "door"
  | "cross"
  | "playPrimary"
  | "logoEntry"
  | "twitterDark"
  | "instDark"
  | "fbDark"
  | "linkDark"

const icons: { [key: string]: FC<SVGProps<SVGSVGElement>> } = {
  checkmark: CheckMarkIcon,
  mobileLogo: LogoMobile,
  language: LanguageIcon,
  door: Door,
  logo: Logo,
  play: Play,
  mute: Mute,
  unmute: Unmute,
  brain: Brain,
  phone: Phone,
  google: Google,
  apple: Apple,
  clock: Clock,
  prev: Prev,
  next: Next,
  prevTablet: PrevTablet,
  nextTablet: NextTablet,
  textLogo: TextLogo,
  complete: Complete,
  twitter: Twitter,
  facebook: Facebook,
  insta: Instagramm,
  linkedin: Linkedin,
  cross: Cross,
  playPrimary: PlayPrimary,
  logoEntry: LogoEntry,
  instDark: InstDark,
  linkDark: LinkDark,
  fbDark: FbDark,
  twitterDark: TwitterDark,
} as const

type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconTypes }

const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null
  return Icon && <Icon {...svgProps} />
}

export { SvgIcon, type SvgIconProps }
