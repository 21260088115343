import { FC, ReactNode, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

interface AuthGuardProps {
  children: ReactNode
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const isHomeRoute = location.pathname === "/" || location.pathname === "/welcome"
    const isAuthenticated = document.cookie.includes("youarewondrful")
    if (isHomeRoute && !isAuthenticated) {
      navigate("/entry")
    }
  }, [location, navigate])

  return children
}

export default AuthGuard
